import { useState } from "react";
import type { EffectCallback } from "react";

export function useOnMount(callback: EffectCallback) {
  const [isFirst, setfirst] = useState(true);

  if (isFirst) {
    callback();
    setfirst(false);
  }
}
